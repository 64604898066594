.content-screen {
  width: 60%;
  min-height: 75vh;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid gainsboro;
  border-radius: 0.6rem;
  padding: 28px 18px;
}
