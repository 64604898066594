.username-avatar {
  font-size: 16px;
  text-transform: uppercase;
  top: 3px;
  position: relative;
  font-weight: 600;
  color: #2f6833;
}

.drop-down {
  top: 80px;
  right: 8px;
  background: #1b4f27;
  display: flex;
  position: absolute;
  padding: 1px;
}

.drop-down-avatar {
  cursor: pointer;
  border-left: 1px solid #024f10;
  padding-left: 1.3rem;
  display: flex;
  align-items: center;
  column-gap: 8px;

  img {
    width: 40px;
    border: 1.5px solid #87a689;
  }
}
