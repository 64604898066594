@import url("https://unpkg.com/mathlive/dist/mathlive-fonts.css");
@import url("https://unpkg.com/mathlive/dist/mathlive-static.css");

.question-box-answer {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

:root {
  --header-height: 3.6rem;
  --border-color: #eaecef;
}

.btn-primary {
  background-color: #024f10;
  border-color: #024f10;
}

.btn-primary:hover {
  background-color: #024f10;
  border-color: #024f10;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--bs-primary);
}

header {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: var(--header-height);
  border-bottom: 1px solid var(--border-color);
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

header > .brand {
  font-weight: bold;
  display: flex;
  align-items: center;
}

header > .brand > a {
  text-decoration: none;
}

header .btn:focus {
  box-shadow: none;
}

main {
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;
}

.spacer {
  flex: 1 1 auto;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: none;
  z-index: 1000;
}

.ML__keyboard {
  z-index: 10031 !important;
}

.backdrop.show {
  display: block;
}

math-field {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}

form.horizontal .form-group {
  display: flex;
  align-items: center;
}

form.horizontal .form-group > .form-label {
  width: 100px;
  margin: 0;
}

form.horizontal .form-group > .form-control,
form.horizontal .form-group > .form-select {
  width: 270px;
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.btn.square {
  width: 38px;
  height: 38px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.select-subject-section {
  .subject-heading {
    margin-left: 1rem;
    margin-bottom: 1rem;
    font-weight: 600;
    color: green;
  }

  .test-series-subjects {
    background: #d3e5cf;
    padding: 2rem;
    border-radius: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    row-gap: 1.2rem;
    margin-bottom: 3rem;

    .test-series-subject-input {
      width: 17rem;
    }
  }
}

.search-assignment {
  height: 40px !important;
  align-self: center !important;
  top: 5px !important;
  position: relative !important;
}

.space-heading {
  font-size: 28px !important;
  text-transform: uppercase;
  letter-spacing: 5px;
  color: rgb(2, 79, 16);
}

.c-pointer {
  cursor: pointer;
}

textarea {
  border: 1px solid gainsboro !important;
}

.arrow-up {
  cursor: pointer;
  color: #4a794d !important;
  position: fixed;
  z-index: 10;
  font-size: 2.5rem;
  right: 16px;
  bottom: 16px;
}
