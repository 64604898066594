.view-question-button {
  float: right;
  margin-top: 2rem;
  width: 4rem !important;
  font-size: 14px;
}

.correct-answer::marker {
  color: green;
}
