.create-screen {
  display: flex;
  width: 100%;
  column-gap: 2rem;

  .edit-section,
  .result-section {
    border: 0.5px solid;
    width: 50%;
    padding: 30px;
    overflow-x: auto;
  }
}
