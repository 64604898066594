@tailwind base;
@tailwind components;
@tailwind utilities;

@import "react-toastify/dist/ReactToastify.min.css";

.Toastify__progress-bar {
  background: rgb(2, 79, 16);
}
.Toastify__toast-icon {
  svg {
    fill: rgb(2, 79, 16);
  }
}

.Toastify__toast-body {
  color: rgb(2, 79, 16);
}

a {
  text-decoration: none;
  color: black;
}
a:hover {
  color: black;
}

.add-view-box {
  background: #edecec !important;
  justify-content: center;
  width: 26rem;
  align-items: center;
  border: 1px solid #024f102b !important;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  border-radius: 1rem;
  padding: 40px 0px;
  h4 {
    font-family: cursive;
    font-size: 30px;
    color: #024f10;
  }

  .add-view-button {
    display: flex;
    column-gap: 10px;
    justify-content: center;
    width: 100%;
  }
}

.add-view-box-flex {
  display: flex;
  justify-content: center;

  gap: 2rem;
  margin-bottom: 1rem;
}

.webkit-center {
  text-align: -webkit-center;
}

.items-1 {
  .icon-wrapper-1 {
    font-size: 5rem !important;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
  overflow-y: scroll !important;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
