.review {
  margin-top: 3rem;

  .input-title {
    margin: auto;
    width: 40%;
    text-align: right;
    .review-title {
      text-align: left;
      label {
        font-weight: 700;
        font-size: larger;
        text-transform: uppercase;
      }
      input {
        height: 3em;
      }
    }
  }

  .review-description {
    margin-top: 1rem;
    .description-review {
      width: 40%;
      margin: auto;
      text-align: right;

      h6 {
        font-weight: 700;
        font-size: larger;
        text-transform: uppercase;
        text-align: left;
      }
      textarea {
        margin-bottom: 0.5rem;
        width: 100%;
        padding: 0.5rem;
      }
    }

    .description-edit {
      display: flex;
      column-gap: 2rem;
      align-items: start;
      margin-top: 2rem;
      width: 100%;

      .input {
        width: 50%;
      }

      .result {
        width: 50%;
        .result-border {
          text-align: left;
          border: 1px solid;
          min-height: 8.5rem;
          padding: 0.1rem 0.5rem;
        }
      }

      textarea {
        width: 100%;
      }
      h4 {
        font-weight: 700;
        font-size: larger;
        text-transform: uppercase;
      }
    }
  }
}
.comment-textarea {
  border: 1px solid gainsboro !important;
}

#reviews {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#reviews td,
#reviews th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

#reviews tr:nth-child(even) {
  background-color: #f2f2f2;
}

#reviews tr:hover {
  background-color: #ddd;
}

#reviews th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04aa6d;
  color: white;
}
