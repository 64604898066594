.all-chapters {
  border-right: 0.5px solid gray;
}

.add-modal-sm {
  height: auto;
  width: 50vw;
  background-color: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 1;
  box-shadow: 1px 3px 10px rgb(0, 0, 0 / 0.2);
  background: #eef2ef;
}

.add-modal {
  height: 100vh;
  width: 100vw;
  background-color: white;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.cross-button {
  cursor: pointer;
  top: 25px;
  right: 25px;
  position: relative;
}

.each-chapter {
  border: 1px solid gainsboro;
  padding: 20px;
  width: 40%;

  .border-r {
    border-right: 0.7px solid gray;
  }
}

.selected-chapter {
  background-color: #6dbd6d52;
  transform: scale(1.02);
  transition: 0.2s;
}

.selected-length {
  font-size: 14px;
  span {
    font-weight: 600;
  }
}

.chapters-list {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .chapter-title {
    width: 30%;
    border-radius: 5px;
    background: #c2d1c3;
    padding: 13px;
    text-align: center;
  }

  .actions {
    display: flex;
    column-gap: 1rem;
    svg {
      cursor: pointer;
    }
  }
}

.section-heading {
  margin-top: 3rem !important;
  font-size: 23px;
  border-radius: 5px;
}
