/* this is to keep you loading wrapper on the middle of screen */
div.pulse-wrapper {
  position: relative;
  margin-top: 7rem !important;
}

div.pulse {
  height: 50px;
  width: 50px;
  background-color: #024f10;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  40% {
    transform: scale(1.1);
    box-shadow: 0 0 0 20px #dae4dc;
  }

  80% {
    transform: scale(1);
    box-shadow: 0 0 0 40px rgba(199, 153, 8, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(199, 153, 8, 0);
  }
}
