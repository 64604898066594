$pri: #fd2549;
$sec: #e5e6f1;

.items-1 {
  height: 18rem;
  border-radius: 5px;
  display: grid;
  grid-template-rows: 2fr 1fr;
  grid-gap: 10px;
  cursor: pointer;
  padding: 2px 4px;
  transition: all 0.6s;
  width: 90%;
}

.icon-wrapper-1,
.project-name-1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper-1 i {
  font-size: 100px;
  color: $sec;
  transform: translateY(0px);
  transition: all 0.6s;
}

.icon-wrapper-1 {
  margin-top: 2rem;
}

.project-name-1 {
  align-self: start;
}

.project-name-1 p {
  font-size: 24px;
  letter-spacing: 1px;
  color: #024f10;
  transform: translateY(0px);
  transition: all 0.5s;
  font-family: cursive;
}

.items-1:hover {
  & .project-name-1 p {
    transform: translateY(-10px);
  }
  & .icon-wrapper-1 i {
    transform: translateY(5px);
  }
}
