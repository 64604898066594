.time {
  display: flex;
}

.time > .indicator {
  cursor: default;
}

.time > .indicator.active {
  background-color: #b5dce9;
  padding: 0 4px;
}

.time .button {
  background-color: #d6ecf3;
  line-height: 1.25;
  padding: 0 8px;
  cursor: default;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.time .button:hover {
  background-color: #b5dce9;
}

.file-label {
  overflow: hidden;
  position: relative;
}

.file-label > [type="file"] {
  cursor: pointer;
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.file-label > .progress {
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100%;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  background-color: var(--bs-primary);
}
.btn-hover {
  background-color: rgb(198, 215, 201) !important;
}
.btn-hover:hover {
  background-color: rgb(2, 79, 16) !important;
  color: white;
}
.btn-active {
  background-color: rgb(2, 79, 16) !important;
}
form.horizontal .form-group > .form-label {
  width: 60px !important;
  margin: 0;
}
.btn-success_ {
  background-color: rgb(2, 79, 16) !important;
}
.btn-primary_ {
  background-color: rgb(198, 215, 201) !important;
}
.btn-w {
  width: 15% !important;
  padding: 0.25rem !important;
}
.question {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.25rem !important;
  padding: 1rem !important;
  cursor: default !important;
}

.question:not(:last-child) {
  margin-bottom: 1rem !important;
}

.question {
  cursor: pointer !important;
}

.question.selected {
  transition: 0.15s;
  border-color: #024f10 !important;
  background: #024f1026;
  transform: scale(1.02);
}

.question > .footer {
  display: flex !important;
}

.view-questions-data {
  div {
    color: black;
    text-align: center;
  }
}

.question > .footer > p,
.view-questions-data {
  color: #6c757d !important;
  font-size: 0.875em !important;
  margin: 0.5rem 0 0 !important;
}
.outline {
  outline: 2px solid white !important;
  outline-offset: -4px !important;
}
.w-10 {
  width: 10% !important;
}
.w-20 {
  width: 20% !important;
}
.w-30 {
  width: 30% !important;
}
.w-40 {
  width: 40% !important;
}
.w-50 {
  width: 50% !important;
}
.w-60 {
  width: 60% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-90 {
  width: 90% !important;
}
