.question-flex {
  display: flex;
  align-items: center;
  gap: 15px;
  width: 100%;

  .count {
    font-size: 22px;
    font-weight: 500;
    color: #024f10;
  }
}

.question-scroll {
  height: 40rem;
  overflow-y: scroll;
  border: 1px solid gainsboro;
  padding: 20px;
  border-radius: 0.5rem;
}
